import React from 'react'
import "./privacy.scss"

const Privacy = () => {
  return (
    <div className='container'>
     <h2> PRIVACY POLICY </h2>
        <p>Important Notice: </p>
        <p>
        Please be mindful of fake sites run by fraudulent parties posing as Hellinda farms and allied or its affiliates. Do not disclose your personal information and financial details to anyone online or anywhere else.         
        </p>  
        <p>
        Hellinda Farms and Allied. (identified herein as “Company” or with “we” or “our”) has developed this policy (the “Privacy Policy”) to inform visitors, partners and investors (identified herein as “you” or “user” or with “your”) to the Company websites that link to this Privacy Policy (referenced collectively herein as the “Sites”) about our data collection, use, and sharing practices.  
        </p>
        <p>
        We may modify this Privacy Policy from time to time.  If we make changes to this Privacy Policy, we will post the new policy at this location.  Please review this Privacy Policy from time to time. 
        </p>
        <h5>What Information Does Hellinda Farms and Allied Collect? </h5>
        <p>
        Personally identifiable information includes information that can be linked to a specific individual, such as a name, address, phone number, or email address. The type of information collected by us will vary with your use of the Sites. We may collect Information when you provide it to us, such as when you contact us, report a problem with our Sites, complete survey forms,  or other promotional program, respond to requests for information or content (such as photographs), leave a review, or when you request to receive communications from us.  
        </p>
        <h4>We may collect the following types of information through the Sites.  </h4>
        <p>
        <b>Name and Contact Information: </b> We may request your name and contact information, such as address, phone number, and email address. Likeness Information:  We may request a photograph containing your likeness. 
        </p>
        <p>
            <b>Demographic Information:</b>
             We may request that you provide demographic and other information about yourself at our Sites as a part of our surveys, sweepstakes, promotional or coupon programs, recipe offerings, advertisements for its products, and/or for management and monitoring purposes in order to continue to improve the Sites and our other online services. </p>
  <p>
  <b>Third-party Information:</b> We may collect personal information and other information about you from third parties and combine it with the information that we collect through the Sites. 
  </p>
  <p>
  <b>Technical Information:</b>  We may automatically collect certain non-personally identifying information when you use the Sites including Internet Protocol addresses; the date and time of access and the Web page(s) accessed; information about your computer, browser, or operating system; and information about your use of the Sites.  
  </p>
  <h4>How Do We Use and Share the Information? </h4>

  <p>Most areas of the Sites are informational and publicly available without providing any Personal information, although we will still collect technical information automatically. Below, is more detail on how we use and share the Personal information that we collect. </p>
 <p>
 <b>Use of Information:</b> We may use the information that we collect  for any lawful commercial or business purpose, such as to provide the products and services that you request, advertise our products and services and the goods and services of third parties, to create a personalized experience for you, to provide you with news and information, to contact you regarding a survey, and to contact you about material changes in our Sites’ policies and agreements, new services we may offer, or otherwise.  With your permission, we may also use information submitted in reviews about our products, including images, to promote or advertise our products.  We may also use a photograph containing your likeness in promotional programs on our Sites or in other marketing materials with your permission. 
 </p>

<p>
<b>Sharing of Information: </b> We may share your Personal information as set forth below: 
</p>
<ul>
    <li>With our affiliates. </li>
    <li>With our service providers, vendors, and contractors that carry out activities on our behalf, such as marketing</li>
    <li>As required by law, such as to a court or governmental agency or in response to a government request</li>
    <li>In the event of a business transaction, such as a merger or acquisition by another company or the sale of all or a portion of our assets, including in bankruptcy, the Personal Information collected by the Company may be among the assets transferred. (In the event a transaction of this kind results in the transfer of Personal Information, we will place a prominent notice on the Sites for 30 days.) </li>
    <li>To protect against misuse or unauthorized use of the Sites or as otherwise needed to limit legal liability, protect or defend our rights, property, or interests, or protect the safety, rights, or property of our users. </li>
    <li>With your consent or as otherwise disclosed to you at the time of data collection or sharing. </li>
</ul>
<p>Survey, Review, and Promotions Results: In addition to the types of sharing 
described 	above, 	we 	may 	share 	survey, 	review, 	and 
sweepstakes/promotions results with the general public, with the news media, and with the Nigerian Government and state governments in order to participate in government programs and initiatives. 
</p>
<p>
<b>Public Forums:</b> If you choose to use public posting features we may offer on the Sites, such as commenting on a blog, the information that you post will be available to the general public. 
</p>
<h4>What About the Use of Cookies? </h4>
<p>
We may use cookies and other similar technologies (collectively, “Cookies”) in connection with your use of our Sites. Cookies are pieces of information that we use for record-keeping purposes, which the Sites store on your computer. Cookies enable us to operate and improve the Sites, such as to customize your experience, save your personal preferences, and ensure that you do not see the same advertisement repeatedly. If you would prefer not to receive Cookies, you may be able to use your browser settings to refuse or delete certain types of Cookies. If you choose to have your browser refuse or delete Cookies, some areas of our Sites will not function properly when you view them.  Even if you use these browser settings, we or third parties operating on the Sites may be able to continue collecting information by other means. 
</p>
<h4>What About Third-Party Links? </h4>
<p>
The Sites include links to third-party sites.  These third-party sites are not controlled by us and, therefore, are not subject to this Privacy Policy. We suggest that you check the privacy policies of these sites to learn how these third-party sites may collect, utilize, or share your information. 
</p>
<p>
The Sites may also include integrated social media tools or “plug-ins”, such as social networking tools offered by third parties.  If you use these tools to share Personal Information or you otherwise interact with social media features on the Sites, those social media companies may collect information about you and may use and share such information in accordance with your account settings, including by sharing such information with the general public.  Your interactions with third-party social media companies and the use of their features are governed by the privacy policies of the social media companies that provide those features.  We encourage you to carefully read the privacy policies of any social media accounts you create and use. 
</p>
<h4>
What About Security? 
</h4>

<p>The Sites have reasonable security measures or safeguards in place to protect against the loss, misuse, and alteration of the information under our control.  However, the confidentiality of any communication or material cannot be guaranteed</p>
<h4>What About Digital Advertising and Analytics? </h4>
<p>
We may partner with ad networks and other ad serving providers (“Advertising Providers”) that serve ads on behalf of us and others on nonaffiliated platforms. Some of those ads may be personalized, meaning that they are intended to be relevant to you based on information that Advertising Providers collect about your use of the Sites and other sites or apps over time, including information about relationships among different browsers and devices. This type of advertising is known as interest-based advertising. 
</p>
<p>
You may visit the DAA Webchoices tool at www.aboutads.info to learn more about this type of advertising and how to opt out of this advertising on websites by companies participating in the DAA self-regulatory program.  If you delete your cookies or use a different browser or mobile device, you may need to renew your opt-out choices exercised through the DAA Webchoices tool.  Note that electing to opt out will not stop advertising from appearing in your browser or applications.  It may make the ads you see less relevant to your interests.  Additionally, your browser may offer tools to limit the use of cookies or to delete cookies; however, if you use these tools, our Site may not function as intended. 
</p>
<p>
We may also work with third parties that collect data about your use of the 
Sites and other sites or apps over time for non-advertising purposes. We use Google Analytics and other third-party services to improve the performance of the Sites and for analytics and marketing purposes. For more information about how Google Analytics collects and uses data when you use our Site, visit www.google.com/policies/privacy/partners, and to opt out of Google 
Analytics, visit tools.google.com/dlpage/gaoptout. 
</p>

<h4>What About "Opting-Out" of Our Data Use and Sharing? </h4>
<p>As you are permitted to browse the Sites without registering, you can decide whether you wish to provide your Personal Information to us when requested. We also provide you with the opportunity to opt out of certain marketing uses of your Personal Information. Specifically, users may opt-out of having us use your Personal Information in connection with direct mailings and/or marketing email communications or newsletters sent by us. </p>
<ul>
    <li>To opt out of email marketing, you can use the “unsubscribe” link included in each marketing email that we send. If you opt out of email marketing, we will still send you emails about your transactions and relationship with us. </li>
    <li>To opt out of direct mailings, you can contact customer service by phone at +2348087970846 or hellindafarmsandallied@gmail.com </li>
</ul>
<p>For choices with respect to third-party interest-based advertising activities, please see the “Digital Advertising & Analytics” section above.  </p>
<p>
Personal Information We Collect, Disclose for a Business Purpose, and Sell.  In the preceding 12 months, we collected and shared the following categories of personal information about consumers:  
</p>
<ul>
    <li>
        Personal and online identifiers (such as first and last name, email address, or unique online identifiers); 
    </li>
    <li>
    	Commercial or transactions information (such as records of products or services purchased, obtained or considered); 
    </li>
    <li>
    	Internet or other electronic network activity information (such as browsing history, search history, interactions with a website, email, application, or advertisement); 
    </li>
    <li>
    	Geolocation information; 
    </li>
    <li>
    	Audiovisual information (such as call recordings or other audio, electronic, visual, thermal, olfactory, or similar information); 
    </li>
    <li>
    	Inferences drawn from the above information about your predicted characteristics and preferences; and 
    </li>
    <li>
    	Other information about you that is linked to the personal information above. 
    </li>
</ul>

<p>
We share personal data for interest-based advertising purposes but do not otherwise engage in “sales” of personal data as defi laws. 
</p>
<h4>Categories of Sources </h4>
<ul>
    <li>Consumers</li>
    <li>
    	Service providers; 
    </li>
    <li>
    	Affiliates not under our brand; 
    </li>
    <li>
    	Consumer data resellers and other business partners; 
    </li>
    <li>
    	Nonprofit organizations; and 
    </li>
    <li>
    	Government entities 
    </li>
</ul>
<p>
Why We Collect, Use, and Share Personal Information. We use and disclose the personal information we collect for our commercial and business purposes, as further described in the “How Do We Use and Share Information?” section of this Privacy Policy. 
</p>
<p>
Recipients of Personal Information. We disclose the categories of personal information designated above to the categories of third parties listed below for commercial and business purposes: 
</p>
<ul>
    <li>
    	Service providers; 
    </li>
    <li>
    	Affiliates not under our brand;
    </li>
    <li>
    	Advertising providers; 
    </li>

    <li>
    	Internet service providers; 
    </li>
    <li>
    	Data analytics providers; 
    </li>
    <li>
    	Operating systems and platforms; 
    </li>
    <li>
    	Social networks; and • Government entities. 
    </li>
</ul>
<p>
Your Rights Regarding Personal Information you have rights with respect to the personal information collected by businesses. You may be able to exercise the following rights regarding your personal information, subject to certain exceptions and limitations: 
</p>
<ul>
    <li>
    	The right to confirm whether we are processing information about you
    </li>
    <li>
    	The right to correct inaccuracies in the personal information we have collected from you. 
    </li>
    <li>
    	The right to know the categories and specific pieces of personal information we collect, use, disclose, and sell about you; the categories of sources from which we collected personal information about you; our purposes for collecting or selling personal information about you; the categories of personal information about you that we have either sold or disclosed for a business purpose; and the categories of third parties with which we have shared personal information about you. 
    </li>
    <li>
    	The right to request that we delete the personal information we have collected from you. 
    </li>
    <li>
    	The right to opt out of processing and sharing of personal data for interest-based advertising. Please note that if you opt out of certain practices, we may be unable to provide you with some services.  Additionally, we do not knowingly sell or share personal data of individuals under 18. 
    </li>
    <li>
    	The right not to receive discriminatory treatment for the exercise of the above privacy rights. 
    </li>

</ul>

<p>
To exercise any of the above rights, please contact us using the following information and submit the required verifying information, as further described below: 
</p>
<ul>
    <li>
    	By email hellindafarmsandallied@gmail.com or • By phone +2348087970846
    </li>


</ul>
<p>
If you have submitted a request that you believe we have not fulfilled, you may contact us to appeal our decision by sending an email with the subject line “Appeal” to hellindafarmsandallied@gmail.com 
</p>
<p>
Verification Process and Required Information. Note that we may need to request additional information from you to verify your identity or understand the scope of your request, although you will not be required to create an account with us to submit a request or have it fulfilled. We will require you to provide, at a minimum first name, last name, email address and we may also need to ask for a mailing address and phone number. 
</p>
<p>
Authorized Agent. You may designate an authorized agent to make requests on your behalf by verifying your identity and providing signed, written permission to the authorized agent to make the request. 
</p>
<h4>What About Correcting, Deleting, or Updating Information? </h4>
<p>
We will retain your Personal Information for as long as necessary to fulfil our business purposes and to comply with applicable laws. At any time, you may contact us to ask us to correct, modify, or delete the information you provide to us through the Sites. Please note, however, that we may need to retain some Personal Information if required by law or for our recordkeeping purposes. Additionally, we need Personal Information to provide certain services through the Sites and the deletion of such Personal 
</p>
<p>
Information, e.g., an email address, may mean that we can no longer provide you with certain services. 
</p>
<h4>
What About Children? 
</h4>
<p>
The Sites are not directed to children and we do not knowingly collect Personal Information from children. If you are a parent or guardian and you believe that your child has provided us with Personal Information, please contact us to have the Personal Information deleted. 
</p>
<h4>
What About Any Other Questions? 
</h4>
<p>
If you have any questions about this Privacy Policy or our practices, you can 
contact 	us 	by 	phone 	at 	+2348087970846, 	by 	email 	at 
hellindafarmsandallied@gmail.com,  
. 
 
 

</p>







    </div>
  )
}

export default Privacy
