import React from 'react'
import Shop from '../../components/shop/Shop'

const Orders = () => {
  return (
    <div className="container" style={{width:"80%!important"}}>
    <Shop/>
</div>
  )
}

export default Orders