import React, { useContext, useState } from 'react'
import "./signup.scss"
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../../components/context/ShopContext';
import api from '../../components/api/api';

const Investors = () => {
    const {loader, resetLoader} = useContext(ShopContext);
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
      idcard: '',
      idcardno: '',
      qualification: '',
      discipline: '',
      occupation: '',
      imageurl: '',
      userrole: 'Investor',
    });
    const onChangeValue = (e) => {
      setUserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 

    const saveData = (e) => {
      e.preventDefault();
      resetLoader(true);
      api.post("/users", userInfo).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("logedinuser", JSON.stringify(data.user))
          alert("Account successfully created, proceed to login")
          navigate("/login");
        }
        resetLoader(false);
      }).catch(error => {
        resetLoader(false);
        if(error.response.status=== 409){
          alert("Email already regisered, login please!")
        }
      })
    }
  return (
    <div className='form-container'>
    <div className="center-content" style={{width:"500px"}}>
      
    <div>
    <form>
      <h3 style={{color:"green"}}>COMPLETE YOUR PROFILE </h3>
      <div className='form-group'>
       <select name="idcard" onChange={onChangeValue} className='form-control'>
        <option value="-1"> -- Select IDCard </option>
        <option value="National"> National ID </option>
        <option value="Voters"> Voters Card </option>
        <option value="Liscence"> Drivers Liscence </option>
        <option value="Liscence"> International Passport </option>
        </select> 
      </div>
     
      <div className='form-group'>
        <input type="text" name="idcardno" id="" className='form-control' 
        onChange={onChangeValue} placeholder='ID Card Number'
        />
      </div>
      <div className='form-group'>
       <select name="qualification" onChange={onChangeValue} className='form-control'>
        <option value="-1"> -- Select qualification </option>
        <option value="B.Sc"> B.Sc </option>
        <option value="M.Sc"> M.Sc </option>
        <option value="MBA"> MBA </option>
        <option value="OND"> OND </option>
        <option value="HND"> HND </option>
        <option value="SSCE"> SSCE </option>
        <option value="NCE"> NCE </option>
        <option value="PhD"> PhD </option>
        </select> 
      </div>
      <div className='form-group'>
        <input type="text" name="discipline" id="" className='form-control'
          onChange={onChangeValue} placeholder='Area of Specialization'
        />
      </div>
      <div className='form-group'>
        <input type="text" name="occupation" id="" className='form-control'
          onChange={onChangeValue} placeholder='Current Occupation'
        />
      </div>
      <div className='form-group'>
      <label htmlFor="phone">Upload Passport:</label>
        <input type="file" name="imageurl" id="" className='form-control'
          onChange={onChangeValue} placeholder='Town/City code'
        />
      </div>
      <div className='form-group'>
        <button type="submit" onClick={saveData} className='button-control' style={{background:'#0085BC',opacity:'.8'}}>
       {loader ?  <span><span className="loader"></span> <span>Processing please wait</span></span>
       :" Save Data"
       
      }
        </button>
      </div>
    </form>
    </div>
    </div>
  </div>
  )
}

export default Investors