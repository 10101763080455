import React from 'react'
import Shop from '../../components/shop/Shop'

const Maincontent = () => {
  return (
    <div>
      <Shop/>
    </div>
  )
}

export default Maincontent
