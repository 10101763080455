import React, { useContext, useState } from 'react'
import MyHelmet from '../../components/Helmets/MyHelmet'
import "./contact.scss"
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../../components/context/ShopContext';
import api from '../../components/api/api';
import SweetAlert2 from 'react-sweetalert2';

const Contact = () => {

  const {loader, resetLoader} = useContext(ShopContext);
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const onChangeValue = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]:e.target.value
    });
  } 

  const sendMessage = (e) => {
    e.preventDefault();
    resetLoader(true);
    api.post("/contacts", userInfo).then(({data})=>{
      if(data.message === "Success"){
        setSwalProps({
          show: true,
          title: 'Success',
          text: 'Message sent successfully, email have been sent to your inbox',
      });
    
        navigate("/");
      }
      console.log(data.message)
      resetLoader(false);
     
    }).catch(error=>{
      console.log(error)
      setSwalProps({
        show: true,
        title: 'Message',
        text: 'Successful',
    });
      resetLoader(false);
    })
  }
  return (
    <div className='major' >
    <div className='contact-container'>
        <MyHelmet title={`Contact Hellinda Farms`} />
        <div className="contact-us-container">
          <div className='row'>
            <h3>CONTACT HELLINDA FARMS</h3>
          </div>
          <div className="columns">
            <p>Email: info@hellindafarms.com</p>
            <p>Phone: +234 8087 970 846</p>
            <p>Address: No 6, Gwandu Close, Directly Opposite Bolton White Hotels, Close to Sahad Stores, Garki Area 11, Abuja (FCT)</p>
          </div>
        </div>
    </div>
    <div className="contact">
          <div className='row-center'><h3>GET IN TOUCH WITH US</h3></div>
          <form className='contact-form'>
            <div className="form-row">
           
              <div className="form-column">
            
              <input type="text" name='name' className="form-control" placeholder='Enter your full name' required
              onChange={onChangeValue}/>
              <input type="text" name='email' className="form-control" placeholder='Enter your email' 
              required
              onChange={onChangeValue} />
              </div>
              <div className="form-column">
                <input type="text" name='phone' className="form-control" placeholder='Enter your Phone number' 
                required
                onChange={onChangeValue} />
                <textarea rows={3} className="form-control" placeholder='Send us a message' 
                required name='message'
                 onChange={onChangeValue}></textarea>
              </div>
            
            </div>
           
            <div className="row-center">
                <button type='submit' className='submit-btn' onClick={sendMessage} >
                {loader ?  <span><span className="loader"></span> <span>Processing</span></span>
              :<span>Send <i class="fa fa-paper-plane-o" style={{fontSize:"18px",color:"white"}}></i></span>
              }
                </button>
            </div>
            <SweetAlert2 {...swalProps} />
            </form>
          </div>
    </div>
  )
}

export default Contact