import React, { useEffect, useState } from 'react'
import pay from "../../images/paystck.png"
import api from '../../components/api/api'
import { PaystackButton } from 'react-paystack';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

let config = {
  reference: (new Date()).getTime().toString(),
  email: '',
  amount: 0, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  // publicKey: 'pk_test_5a51f78313c6d5cea55e77dafb8adbb0dba0cb82',
  publicKey: 'pk_live_eeae4531150612d41299b54cbb6ffa80bfe286ef',
};
const InvestorPayment = () => {
  const [item, setItems] = useState({})
  let user = JSON.parse(localStorage.getItem("logedinuser"));
  const {id} = useParams()
  
  if(user==null){
    window.location ="/userdashboard"
  }
  useEffect(()=>{
    api.get(`investment/${id}`).then(({data})=>{
     setItems(data.investment)
     config.amount= parseInt(data.investment.amount)*100
    config.email=user.email
    }).catch((err)=>{})
  }, [])

  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    
    reference.user_id = user.id;
    reference.amount  = config.amount;
    reference.channel = "Paystack";
   
    api.post(`investor-payment/${item.id}`, reference).then(({data})=>{
      Swal.fire({
        text:data.message,
        icon:"success"
      })
      window.location = '/userdashboard'
    }).catch((err)=>{
      alert("Payment not successful")
       window.location = '/userdashboard'
    });
   
    //  window.location = "/userdashboard"
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alert("Transaction not successful")
    // window.location = "/userdashboard"
  }

  const componentProps = {
      ...config,
      text: `Pay Now ${config.amount/100}`,
      onSuccess: (reference) => handlePaystackSuccessAction(reference),
      onClose: handlePaystackCloseAction,
  };

  return (
    <div className='invoicecontainer'>
           
          
           <b>HELLINDA FARMS AND ALLIED</b>
            <p> No 6 Gwandu Street, Garki Area 11, Abuja-FCT</p>
            <i>admin@hellindafarms.com</i>
            <br/>
            <b>INVESTOR DETAILS</b>
            <p>Name: {user.firstname} {user.surname}</p>
            <p style={{color:"darkgreen"}}>{user.address}</p>
      {/* <hr /> */}
      
      <div>
      <PaystackButton {...componentProps} className='pay-now' />
       
      </div>
      <img src={pay} alt="" width={350}/>
    </div>
  )
}

export default InvestorPayment