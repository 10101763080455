import React, { useContext } from 'react'
import "./cards.scss";
import { ShopContext } from '../context/ShopContext';
import { Server } from "../../components/api/server"
let server = new Server()



export const Cards = (props) => {
    
  const GlobalState = useContext(ShopContext)
  const addToCart = GlobalState.addToCart;
    let {increaseCart, qty, decreaseCart} = GlobalState;
 


  return (
    <div className='cards-container' >
        <div className="card-image">
            <img src={`${server.url}/${props.item.imageurl}`} alt="" /> 
        </div>
        <div className="card-cart">
            <div className="cart-title">
            {props.item.name}
            </div>
            <span className='stars'>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <span> &nbsp; 245 views</span>
            </span>
            <p className="cart-desc">
            {props.item.description}
            </p>
            <span>LIMITED TIME ONLY SAVE 29% </span>
            <span><b>NGN {props.item.price} </b></span>

            {props.item.quantity < 1 ?
            <span className="cart-btn" onClick={()=> addToCart(props.item)}> 
              <i className="fa fa-shopping-cart" ></i>&nbsp;
            Add to Cart
            </span>
            :
            <div className="cartitem-header">
              <button className='minus' onClick={()=> {
                decreaseCart(props.item)
               window.location.reload(false);
              }}>-</button>
              <input type='number' className='count' value={props.item.quantity}  min={1} onChange={()=>{}}/>
              <button className='plus' onClick={()=>{
                 increaseCart(props.item);
                 window.location.reload(false);
                 }}> + </button>
            </div>
            }

        </div>
    </div>
  )
}
