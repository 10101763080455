import React, { useContext, useState } from 'react'
import "./signup.scss"
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../../components/context/ShopContext';
import api from '../../components/api/api';

const Login = () => {

    const {loader, resetLoader} = useContext(ShopContext);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
      email: '',
      password: '',
    });
    const onChangeValue = (e) => {
      setUserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 

    const loginUser = (e) => {
      e.preventDefault();
      resetLoader(true);
      api.post("/login", userInfo).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("logedinuser", JSON.stringify(data.user))
          window.location = "/userdashboard";
        }
        resetLoader(false);
        console.log(data)
      }).then(error=>{
        resetLoader(false);
        console.error(error)
      })
    }
  return (
  
    <div className='form-container' >
      <div className="center-content" style={{paddingBottom:"50px"}}>
          
      <div >
      <form action="">
        <h3 style={{color:"red", fontWeight:"800", paddingTop:"20px"}}>RECOVER YOUR PASSWORD</h3>
        <hr style={{margin:"30px 0"}}/>
        <div className='form-group'>
          <label htmlFor="email" style={{fontSize:"16px", marginBottom:"10px"}}>Email Address:</label>
          <input type="email" name="email" id="" 
          placeholder='Enter your active email registered wtih us!' 
          className='form-control' onChange={onChangeValue} style={{padding:"12px 8px"}}/>
        </div>

        <div className='form-group'>
        <button type="submit" onClick={loginUser} className='button-control'>
         {loader ?  <span><span className="loader"></span> <span>Processing please wait</span></span>
         :"Recover Now"
        }
        </button>
        </div>
      </form>
    </div>
    </div>
    </div>
 

  )
}

export default Login