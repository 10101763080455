import React from 'react'
import "./chickens.scss"
import chichen from "../../images/full-grown-chicken.jpg"

const Chickenx = ({chickens, setChickens}) => {
  return (
    <div className={!chickens ? "chickens" : "hide"}>
        <span className='close' onClick={()=>{setChickens(!chickens)}}> <i class="fa fa-times" aria-hidden="true"></i> </span>
        <img src={chichen} alt=''/>
    </div>
  )
}

export default Chickenx