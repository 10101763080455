import First from "./../../images/img-1.jpg"
import Second from "./../../images/img-2.jpg"
import Third from "./../../images/img-3.jpg"
import Fourth from "./../../images/img-1.jpg"



export default [
    {
        title: "Processing ",
        description: "Our Chickens are slaughtered and processed in a clean sanitary and hygienic environment inspected by NAFDAC.",
        urls: First
    },
    {
        title: "Quality Assurance",
        description: "100% Quality Assured",
        urls: Second
    },
    {
        title: "Packaging",
        description: "Our packaging materials are sterilized with air ultraviolet light sterilizer to be sure of no bacteria growth on our product, maintaining the best quality",
        urls: Third
    },
    {
        title: "Raw Material",
        description: "We are good at what we do. This is Hellinda Farms quality. The quality you are looking for. ",
        urls: Fourth
    },
]