import MyHelmet from '../../components/Helmets/MyHelmet';
import React from 'react'
import "./about.scss"
import ceo from "../../images/ceo_1.jpg"

const About = () => {
  return (
    <div className='about-container'>
       
        <MyHelmet title={`About Hellinda Farms`} url={`about`} />
        
        <div className="about-wrapper">
       <div className="header-bg">
       <h3 className='title-text' style={{fontSize:"50px"}}>ABOUT HELLINDA FARMS</h3>
       </div>
          <h4 className='title-text paddingTop'>OUR STORY</h4>
          <p>
          Hellinda Farms and Allied Story starts with one man, David Injorovkaa who initially established a
        chicken farm in his village JATO/AKA KWANDE benue state In 2016 with his late father (Injorovkaa Gbabe). Thanks
        to the hard work and determination of DAVID and his father (Injorovkaa Gbabe). The company grew and to this point
        moved it head office to Abuja Nigeria with it processing plant at No.6, Gwandu Street Garki Area 11
        Abuja Nigeria in 2022 during which it's was incorporated with corporate affairs commission (CAC).
        We believe that our success lies in listening to customers, changing and evolving to meet
        consumer needs and staying true to our core values, which includes producing quality food,
        protecting the environment, providing for the welfare of the animals and caring about others.
          </p>
          <p>
          We understand that we may not be the cheapest option but we are certain that we offer the best
          that your money can buy. We hand-select the finest cuts of meat and hand-curate each and every
          bundle, based on customer needs and feedback. And we're pretty sure you won't find better flavor.
          That thanks to the way in which our staff handle livestock: thoughtfully and sustainably
          </p>
          <div class="headers">
        <div class="titles">
        <h2 class="title-text paddingTop">WHY CHOOSE HELLINDA FARMS.</h2>
        {/* <h5 class="sub-title"> Why Choose Hellinda Farms?</h5> */}
        </div>
       <div class="row-lines">
            <span class="first-line"></span>
            <span class="middle-line"></span>
            <span class="last-line"></span>
       </div>
    </div>
          <h4>We stand apart from the rest.</h4>
          <p>
          Have you ever ask yourself this question, why you should stop where you have been buying meat
          and go for HELLINDA FARMS products??

          </p>
          <p>
          We know you have many options, but what separate us from those your options & more
          importantly why trust us as your primary source for premium protein, it's all come down to our
          natural taste, quality & variety, a combination that you can only find at Hellinda Farmsand Allied
          </p>
          <p>
          OUR VISION: "To be the most trusted brand in food and Agricultural products.
          OUR VALUES SHAPE OUR FUTURE: our values are established since our inception and they
          continue to guide our actions today as we work toward our vision. "To be the most trusted name in
          food and Agricultural products.
          </p>
          <p>
          QUALITY: we live up to the standards and expectations of our quality policy, penned under the
          direction of our CEO(Injorovkaa David Aondover)
          We shall produce products and provide services at all times that meet or exceed the
          expectations of our customers.
          </p>
          <p>
          We shall not be content to be of equal quality to our competitors.
          our commitment is to be increasingly superior
          contribution to equality is responsibility shared by everyone in the HELLINDA ORGANIZATION.
          </p>
          <p>
          <b>INTEGRITY:</b> we do the right thing for the right reason and always keep our promises.
          </p>
          <p>
         <b> TEAMWORK:</b> we work together to accomplish something greater than one could alone.
          </p>
          <p>
          We value and protect the safety of others.
          We make each other successful.
          </p>
          <p>
          <b>STEWARDSHIP:</b> we protect our environment, ensure the well-being of our associates, provide for
            the welfare of the animals in our care, live up to our civic responsibilities and generate earnings for
            the future of our company.
          </p>
          <div >

          <div class="headers">
        <div class="titles">
        <h2 class="title-text paddingTop">ABOUT THE FOUNDER</h2>
        <h5 class="sub-title"> Biography of David Aondover Injorovkaa</h5>
        </div>
       <div class="row-lines">
            <span class="first-line"></span>
            <span class="middle-line"></span>
            <span class="last-line"></span>
       </div>
       </div>
       <div className="ceo-container">
       <section className="left">
             <img src={ceo} alt="CEO" />
           </section>
            <section className="right">
            <h4>AONDOVER DAVID INJOROVKAA</h4>
              <p>
                Injorovkaa David Aondover was born on 6 June 1994 in general hospital Jato - aka Turan Kwande local government Area of Benue State, Nigeria.	
              </p>

              <p>
              His father Injorovkaa  gbabe was a Tiv man, a farmer , and a primary school teacher  with Teachers Service Board, but later transfer to universal Basic Education (UBE) where He served through out as principal. His father Injorovkaa Gbabe was a lover of education. Who started his educational Journey from primary school to master’s degree level in special 	education from university of Jos.
              </p>
              <p>
              And his mother AHAMBEE Injorovkaa is a farmer and only schooled up to secondary level.	His maternal father ADELE was a hunter from the Turan, Injoshin Jato-aka kwande local Government Area of Benue State. His father ever contested for councillor in Mbadura council world Turan kwande LGA Benue state but did not won.  He again towards his retirement as a teacher contested as kwande LGA Educationa secretary (ES) But during the process had a stroke in 2016 and was down till his death in January 2023.	His father got married to the first wife who died after having a child ACHINKUMBU INJOROVKAA he then married a second wife AHAMBEE who is still alive with 8 children namely:
              </p>
              <p>
              1.	Ahileshima Helen Injorovkaa
2.	Mornan Linda Injorovkaa
3.	Gandekigh Daniel Injorovkaa
4.	Tertsea, Solomon Injorovkaa
5.	AONDOVER David Injorovkaa
6.	Denen Gaius Injorovkaa
7.	Imoter Geoffrey Injorovkaa
8.	Gudangu Gideon Injorovkaa.


              </p>
            </section>
       </div>
       <div className='story' >
        <p>
        His father had a total of 9 children, Injorovkaa David Aondover is number 5th among his mother’s children and number 6 in the family.	
        </p>
        <h4 className='title-text paddingTop'>EDUCATION </h4>
        <p>
        For the great love and Trust his father had on education he made sure all his children were educated. Injorovkaa David Andover started his schooling at NKST primary school TUM Turan Jato-aka, Kwande LGA in 1999 from primary one and graduated in 2005. 
        </p>
        <p>
        Injorokaa David Aondover graduated from primary school the same year with his elder brother Injorovkaa Tertsea in 2005, they both went for an entrance examination at government model college Katsina-Ala (GCK) but only the elder brother was admitted because of the same surname based on the high number that applied that year, the school could not give admission to all and decided to admit only one person from the same father.
        </p>
        <p>
        Injorovkaa Aondover went back and got admitted into NKST secondary school Jato aka in sept 2005 in his village but attended the school for only first term and transferred to Turan community secondary school Jato-Aka by January 2006 because of long distance from his house to the school. He deed only second and third term during his JSS 1 at Turan Community Secondary School Jato/Aka and transfer back to Government model college (GCK) Katsina Ala, Where he continue from JSS2 to JSS3. Again after his JSS 3 at (GCK) told his dad that he wanted to study sciences and was transferred to Special Science senior secondary school makurdi in September 2008 where he continued and completed his secondary school from SS1 to SS3 He graduated from Special science senior secondary school Makurdi in 2011.
        </p>
        <p>
        In 2012 he got admission into federal university of agriculture Makurdi in B.agric (Animal production) and started school by January 2013. He graduated from university of Agriculture Makurdi currently known as Joseph Sanwuan Tarkaa University Makurdi in 2018.
        </p>
        <h4 className='paddingTop'>NYSC</h4>
        <p>
        In March 2019 Injorovkaa David Aondover was posted to Kebbi state for his one year mandatory NYSC service where he served meritoriously and was elected as the secretary National Association of Catholic coppers (NACC) Kebbi, state chapter. He completed his NYSC service in March 2020
        </p>
        <div class="headers">
        <div class="titles">
        <h2 class="title-text paddingTop">CAREER </h2>
        {/* <h5 class="sub-title"> Why Choose Hellinda Farms?</h5> */}
        </div>
       <div class="row-lines">
            <span class="first-line"></span>
            <span class="middle-line"></span>
            <span class="last-line"></span>
       </div>
    </div>
       </div>
          
          </div>
        </div>
    </div>
  )
}

export default About