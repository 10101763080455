import React , {createContext, useEffect, useState} from 'react'

export const ShopContext = createContext();

const ShopContextProvider = (props) => {
    const [loader, setLoader] = useState(false);
    const [cartitems, setCartitems] = useState([]);
    const [totalCart, setTotalCart] = useState(0);
    const [counter, setCounter] = useState(0);
    const [qty, setQty] = useState(0);

    const addToCart = (record) => {
        const existingCart = JSON.parse(localStorage.getItem("cartitems")) || []
        const tempstate = existingCart.filter((item)=>item.id === record.id)
       
        if(tempstate.length < 1){
            setCounter(counter+1)
            record.quantity = 1;
            let updateItem = [...existingCart, record]
            localStorage.setItem("cartitems", JSON.stringify(updateItem))
        }
    }

    const increaseCart = (record) => {
        const existingCart = JSON.parse(localStorage.getItem("cartitems"))
        const updateItem = existingCart.map((item)=>{
            if(item.id === record.id){
                item.quantity = item.quantity + 1;
            }
            setCounter(counter+1);
            return item;
        })
        localStorage.setItem("cartitems", JSON.stringify(updateItem))
    }

    const decreaseCart = (record) => {
        const existingCart = JSON.parse(localStorage.getItem("cartitems"))
        const updateItem = existingCart.map((item)=>{
            if(item.id === record.id && item.quantity > 0){
                item.quantity = item.quantity - 1;
                setCounter(counter-1);
            }
            return item;
        })
        localStorage.setItem("cartitems", JSON.stringify(updateItem))
    }
    
   
    const logout = ()=>{
        localStorage.clear();
        window.location = "/"
    }

    const resetLoader = (val) =>{
        setLoader(val);
    }

    useEffect(()=>{
        const existingCart = JSON.parse(localStorage.getItem("cartitems")) || []
        setCartitems(existingCart)
        const count = existingCart.reduce(function(pre, next){
            return pre + next.quantity;
        }, 0)
    setQty(count)
    },[counter, qty])

    
   
    const contextValues = {
        loader,
        resetLoader,
        logout,
        addToCart,
        increaseCart,
        cartitems,
        decreaseCart,
        totalCart,
        setTotalCart,
        qty,
    };

  return (
    <ShopContext.Provider value={contextValues}>
        {props.children}
    </ShopContext.Provider>
  )
}

export default ShopContextProvider