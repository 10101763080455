import React from 'react'
import "./partner.scss"
import { Link } from 'react-router-dom'
import partner from "../../images/partners.png"
import rider from "../../images/rider.jpeg"
// import career from "../../images/happy-young-african-businessman_171337-736.avif"

const Partner = () => {
  return (
    <div className='partner-container'>
        <div className="wrapper">
            <div className="card">
            <img src={rider} alt="" />
                <h3 className="card-title">
                Become a rider
                </h3>
                <p>
                Enjoy flexibility, freedom and competitive earnings by delivering through Hellinda.
                </p>

                <Link to={"/riders"} className='register-here'>
                    Register Here
                </Link>

            </div>
        </div>
        <div className="wrapper">
            <div className="card">
                <img src={partner} alt="" />
                <h3 className="card-title">
                Become a partner
                </h3>
                <p>
                Grow with Hellinda Farms! Our Farms, technology and user base can help you boost sales and unlock new opportunities!
                </p>

                <Link to={"/investment"} className='register-here'>
                    Register Here
                </Link>

            </div>
        </div>
        <div className="wrapper">
            <div className="card">
            {/* <img src={career} alt="" /> */}
                <h3 className="card-title">
                Careers
                </h3>
                <p>
                Ready for an exciting new challenge? If you’re ambitious, humble, and love working with others, then we want to hear from you!
                </p>

                <Link to={"/careers"} className='register-here'>
                    Register Here
                </Link>

            </div>
        </div>
        
    </div>
  )
}

export default Partner