import React, { useContext, useEffect, useState } from 'react'
import { Cards } from '../Cards/Cards'
import { ShopContext } from '../context/ShopContext'
import api from '../api/api'
import "./shop.scss"

const Shop = () => {
    const GlobalState = useContext(ShopContext)
    const [products, setProducts] = useState([])
    let difference = [];
    useEffect(()=>{
        const cartitems = JSON.parse(localStorage.getItem("cartitems"))
        api.get("products").then(({data})=>{
            
            if(cartitems !== null){
                difference = data.filter(({ id: id1 }) => !cartitems.some(({ id: id2 }) => id2 === id1));
            }
            if(cartitems === null){
                setProducts(data)
            }else{
                 setProducts(cartitems.concat(difference))
            }
        }).catch((error)=>{

        })
    }, [])

    

  return (
        <div className='shop-container'> 
            {
                products.map((items)=>(
                    <Cards item={items} key={items.id}/>
                ))
            }
        </div>

  )
}

export default Shop