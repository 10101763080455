import React, { useContext, useState } from 'react'
import "./signup.scss"
import admin from "../../images/administrator-male.png"
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../../components/context/ShopContext';
import api from '../../components/api/api';
import SweetAlert2 from 'react-sweetalert2';

const Login = () => {

    const {loader, resetLoader} = useContext(ShopContext);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [swalProps, setSwalProps] = useState({});

    const [userInfo, setUserInfo] = useState({
      email: '',
      password: '',
    });
    const onChangeValue = (e) => {
      setUserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 

    const loginUser = (e) => {
      e.preventDefault();
      resetLoader(true);
      api.post("/login", userInfo).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("logedinuser", JSON.stringify(data.user))
          setSwalProps({
            show: true,
            title: 'Success',
            text: '',
        });
        const myurl = localStorage.getItem("myurl")
        if(myurl){
          window.location=myurl;
        }else{
          window.location = "/userdashboard";
        }
          
        }
        resetLoader(false);
       
      }).catch(error=>{
        setSwalProps({
          show: true,
          title: 'Error',
          text: 'Incorrect email or password',
      });
        resetLoader(false);
      })
    }
  return (
  
    <div className='form-container' >
      <div className="center-content" style={{paddingBottom:"50px"}}>
          <div className='admin-male'>
            <span onClick={()=>navigate("/signup")}>Sign Up</span>
            <img src={admin} alt="" />
            <label onClick={()=>navigate("/recoverpassword")} className='forgotpassword'>Forgot Password</label>
          </div>
      <div >
      <form action="">
        <h3>EXISTING USER LOGIN</h3>
        <hr/>
        <div className='form-group'>
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="" className='form-control' onChange={onChangeValue} style={{}}/>
        </div>

        <div className='form-group'>
          <label htmlFor="password">Password</label>
          <div className="password-wrapper">
            <input 
            type={
              showPassword ? "text" : "password"
            }
            name="password" 
            onChange={onChangeValue}
            id="" className='form-control'/>
            <span>
              {showPassword ? <i className="fa fa-eye" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i> 
              :  <i class="fa fa-eye-slash" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i>
              }
            </span>
          </div>
        </div>
        <div className='form-group'>
        <button type="submit" onClick={loginUser} className='button-control'>
         {loader ?  <span><span className="loader"></span> <span>Processing please wait</span></span>
         :"Login"
        }
        </button>
        </div>
       
        <SweetAlert2 {...swalProps} />
      </form>
    </div>
    </div>
    </div>
 

  )
}

export default Login