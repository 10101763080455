import React from 'react'
import './products.css'
import crop from "../../images/chicks_bg.png";
import rice from "../../images/rice.jpg";
import maize from "../../images/maize.jpg";
import chicken from "../../images/manychicks_bgwhite.jpg";
import soyabean from "../../images/soybeans.jpg";
import ventilator from "../../images/Tunnel-Ventilation.jpg";
import harvester from "../../images/conbine-hervester.png";
import chicken2 from "../../images/multiple_chicks.jpg";
import Shop from '../../components/shop/Shop';


const Chickens = () => {
  return (
    <div className='crop-container'>
        <div className="crop-background">
            <img src={crop} alt="" />
        </div>
    <div className='content-section'>
    <div class="headers">
        <div class="titles">
        <h1 class="title-text">Farmers For Our Brands.</h1>
        </div>
       <div class="row-lines">
            <span class="first-line"></span>
            <span class="middle-line"></span>
            <span class="last-line"></span>
       </div>
       <p>
       The brands of Hellinda Farms work with small family farmers and ranchers across the country, who carry on an agricultural tradition with the intention to hand over to their children and grandchildren. Networks are comprised of cattle ranchers, sheep farmers, hog farmers, poultry farmers and crop farmers, who must follow a specific (and strict!) set of farming protocols to raise livestock and crop for Hellinda brands
       </p>
       <p>
       Here, we bring more farmers together and share about their hard work, day-to-day farming life and challenges, plus efforts we are taking to support them and ensure that their families can farm for years to come.
        <span> </span> <a href="/signup"> <b>Register</b> to be part of us today, invest a particular amount and part of our profit sharing.</a>

       </p>
    </div>
    <div className="content-row">
        
        <div className="content-column">
            <div className="crop-image">
            <img src={chicken} alt="" />
            </div>
            <h3 className='crop-title'>
                Chicken Farm
            </h3>
            <p className="description">
            Each order supports: Our family of farmers, who preserve land through sustainable farming practice
            </p>
            <a href="/Chickens" className="crop-link">Read More</a>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={soyabean} alt="" />
            </div>
            <h3 className='crop-title'>
                Soyabeans Farm
            </h3>
            <p className="description">
            Each order supports: Our family of farmers, who preserve land through sustainable farming practice
            </p>
            <a href="/Chickens" className="crop-link">Read More</a>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={rice} alt=""/>
            </div>
            <h3 className='crop-title'>
            Rice Farm
            </h3>
            <p className="description">
                Description
            </p>
            <a href="/Chickens" className="crop-link">Read More</a>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={maize} alt=""/>
            </div>
            <h3 className='crop-title'>
                Maize Farm
            </h3>
            <p className="description">
                Description
            </p>
            <a href="/Chickens" className="crop-link">Read More</a>
        </div>

    </div>
    </div>
       
    <Shop/>
    </div>
  )
}

export default Chickens
