import React, { useContext, useState } from 'react'
import "./signup.scss"
import "./dashboard.css"
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../../components/context/ShopContext';
import api from '../../components/api/api';


const Nextofkin = () => {
    const {loader, resetLoader} = useContext(ShopContext);
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
      idcard: '',
      idcardno: '',
      fullname: '',
      phone: '',
      email: '',
      relationship: '',
      Address: '',
      status: '',
      dob: '',
      imageurl: '',
    });
    const onChangeValue = (e) => {
      setUserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 

    const saveData = (e) => {
      e.preventDefault();
      resetLoader(true);
      api.post("/users", userInfo).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("logedinuser", JSON.stringify(data.user))
          alert("Account successfully created, proceed to login")
          navigate("/login");
        }
        resetLoader(false);
      }).catch(error => {
        resetLoader(false);
        if(error.response.status==409){
          alert("Email already regisered, login please!")
        }
      })
    }
  return (
    <div className='form-container shadow90' style={{background:"#F6f6f6"}}>
    <div className="center-content" style={{width:"500px"}}>
      
    <div>
    <form>
      <h3 style={{color:"green"}}>NEXT OF KIN INFORMATION </h3>
      <div className='form-group'>
        <input type="text" name="fullname" id="" className='form-control' 
        onChange={onChangeValue} placeholder='Full Name (Surname first)'
        />
        </div>
       
        
      <div className='form-group'>
        <input type="text" name="email" id="" className='form-control'
          onChange={onChangeValue} placeholder='Next f kin email'
        />
      </div>
      <div className='form-group'>
       <select name="relationhship" onChange={onChangeValue} className='form-control'>
        <option value="-1"> -- Select relationship </option>
        <option value="Brother"> Brother </option>
        <option value="Sister"> Sister </option>
        <option value="Aunty"> Aunty </option>
        <option value="Uncle"> Uncle </option>
        <option value="Father"> Father </option>
        <option value="Mother"> Mother </option>
        <option value="Wife"> Wife </option>
        <option value="Husband"> Husband </option>
        <option value="Friend"> Friend </option>
        </select> 
      </div>
      
      <div className='form-group'>
        <input type="text" name="Address" id="" className='form-control'
          onChange={onChangeValue} placeholder='Home address'
        />
      </div>
      <div className='form-group'>
      <label htmlFor="phone">Date of Birth: </label>
        <input type="date" name="dob" id="" className='form-control'
          onChange={onChangeValue} placeholder='Date of Birth'
        />
      </div>
     
      <div className='form-group'>
       <select name="idcard" onChange={onChangeValue} className='form-control'>
        <option value="-1"> -- Select IDCard </option>
        <option value="National"> National ID </option>
        <option value="Voters"> Voters Card </option>
        <option value="Liscence"> Drivers Liscence </option>
        <option value="Liscence"> International Passport </option>
        </select> 
      </div>
     
      <div className='form-group'>
        <input type="text" name="idcardno" id="" className='form-control' 
        onChange={onChangeValue} placeholder='ID Card Number'
        />
      </div>
      
      <div className='form-group'>
      <label htmlFor="phone">Upload Passport (Optional):</label>
        <input type="file" name="imageurl" id="" className='form-control'
          onChange={onChangeValue} placeholder='Town/City code'
        />
      </div> 
      <div className='form-group'>
        <button type="submit" onClick={saveData} className='button-control' style={{background:'#0085BC',opacity:'.8'}}>
       {loader ?  <span><span className="loader"></span> <span>Processing please wait</span></span>
       :" Save Data"
       
      }
        </button>
      </div>
    </form>
    </div>
    </div>
  </div>
  )
}

export default Nextofkin