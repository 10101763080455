import React from 'react'
import { Helmet } from 'react-helmet';

const MyHelmet = ({title, url, desc}) => {
  return (
    <div>
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />

        <meta property="og:title" content={`${title}`} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={`https://hellindafarms.com/images/image.jpg`} />
        <meta property="og:url" content={`/${url}`} />
        <meta property="og:type" content="website" />  

        <meta name="twitter:title" content={`${title}`} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={`URL of the image you want to use`} />
        <meta name="twitter:card" content="summary_large_image" />  
    </Helmet>
    </div>
  )
}

export default MyHelmet