import React, { useEffect, useState } from 'react'
import pay from "../../images/paystck.png"
import api from '../../components/api/api'
import logo from "../../images/logo1.png"
import { PaystackButton } from 'react-paystack';

let config = {
  reference: (new Date()).getTime().toString(),
  email: '',
  amount: 0, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  // publicKey: 'pk_test_5a51f78313c6d5cea55e77dafb8adbb0dba0cb82',
  publicKey: 'pk_live_eeae4531150612d41299b54cbb6ffa80bfe286ef',
};
const Payments = () => {
  const [items, setItems] = useState([])
  const [payment, setPayment] = useState({})
  let total = 0;
  let user = JSON.parse(localStorage.getItem("logedinuser"));
  
  
  useEffect(()=>{
    api.get(`getinvoice/${user.id}`).then(({data})=>{
      setItems(data.oda)
      localStorage.setItem("order", JSON.stringify(data.pmt))
      setPayment(data.pmt)
       config.email= user.email
       config.amount=data.pmt.amount*100;
    })
  }, [total])

  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    reference.user_id = user.id;
    reference.amount  = config.amount;
    reference.channel = "Paystack";
   
    api.post(`my-payment/${payment.id}`, reference).then(({data})=>{
      window.location = '/userdashboard'
    }).catch((err)=>{
      alert("Payment not successful")
       window.location = '/userdashboard'
    });
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    window.location = '/'
    // window.location = "/userdashboard"
  }

  const componentProps = {
      ...config,
      text: `Pay Now ${config.amount/100}`,
      onSuccess: (reference) => handlePaystackSuccessAction(reference),
      onClose: handlePaystackCloseAction,
  };

  return (
    <div className='invoicecontainer'>
     
            <img src={logo} alt='Hellinda Logo' style={{width:"100px"}}/>
            <p>Head Office: N0 11 Close, Garki, Abuja-FCT</p>
            <h3>Customer Name: {user.firstname} {user.surname}</h3>
            <p style={{color:"darkgreen"}}>Contact Address: {user.address}</p>
          
       
      <hr />
      <table className="table">
        <tr>
          <th>Item</th>
          {/* <th>Quantity</th> */}
          <th>Amount</th>
          {/* <th>Subtotal</th> */}
        </tr>
      {
        items.map((item)=>{
          total += item.quantity * item.amount
          return(
                <tr>
                  <td>{item.name}</td>
                  {/* <td>{item.quantity}</td> */}
                  <td>{item.quantity} X {item.amount}</td>
                  {/* <td>{item.amount * item.quantity}</td> */}
                </tr>
          )
        })
        
      }
      </table>
      <hr />
      
      <table className="table">
        <tr>
          <th></th>
          <th></th>
          <th>Total Amount:</th>
          <th>{total}</th>
        </tr>
      </table>
     
      <hr />

      <div>
      <PaystackButton {...componentProps} className='pay-now' />
       
      </div>
      <img src={pay} alt="" width={350}/>
    </div>
  )
}

export default Payments