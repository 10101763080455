import React from 'react'
import"./investment.scss"
import retailer from "../../images/retailer.jpg"
import MyHelmet from '../../components/Helmets/MyHelmet'
import logistics from "../../images/coolinvan_3.jpg"
import coldroom from "../../images/coldroom.jpg"
import truck from "../../images/truck.jpg"
import dispatch from "../../images/dispatch_rideer.jpg"
import Tricycle from "../../images/trycycle.jpg"

const Investment = () => {
    const desc = "About Hellinda Farms, Farms in Abuja Nigeria, Best Farm, chichen farms, chicken meat, for your household "

  return (
    <div className='investment-container'>
         <MyHelmet title={`Investors (Invest in Hellinda Farms & Allied)`} url={`/`} desc={desc}/>
     <div className="teams">
     <div class="headers">
        <div class="titles">
        <h2 class="title-text">Meet the Team.</h2>
        {/* <h5 class="sub-title"> Why Choose Hellinda Farms?</h5> */}
        </div>
       <div class="row-lines">
            <span class="first-line" style={{visibility:"hidden"}}></span>
            <span class="middle-line" style={{background:"green"}}></span>
            <span class="last-line" style={{visibility:"hidden"}}></span>
       </div>
    </div>
     <p>
     Teamwork makes hellinda work.
At Hellinda, we work together to achieve our business objectives and our vision.
Our team is driven by the same goal – to deliver only the best quality products and services possible to our consumers.
Join our team and become a life time beneficiary.

 <a href='/investors'>Invest Now</a>
     </p>
     </div>
     <div className="investors-section">
     <div class="headers">
        <div class="titles">
        <h2 class="title-text">HELLINDA FARMS INVESTMENT CATEGORIES.</h2>
        <h5 class="sub-title"> choose to Invest with Hellinda Farms today, you will never regret</h5>
        </div>
       <div class="row-lines">
            <span class="first-line" ></span>
            <span class="middle-line" ></span>
            <span class="last-line" ></span>
       </div>
    </div>

    <div className="card-row">
        <div className="card">
            <div className="card-image">
            <img src={retailer} alt='Retailer'/>
            </div>
            <div className="card-title">
                <h4>Retailers </h4>
            </div>
            <div className="card-content">
                <p>
                Do you have a freezer and a retail space where you feel at ease? If you answered yes, you are one step closer to becoming a Hellinda retailer partner. Sellers in this category can begin on a small scale with as little as N50,000 - N100,000, straight from the comfort of their own homes.
                </p>
            </div>
            <div className="card-footer">
            <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>


        <div className="card">
            <div className="card-image">
            <img src={retailer} alt='Retailer'/>
            </div>
            <div className="card-title">
                <h4>Sub-distributors </h4>
            </div>
            <div className="card-content">
                <p>
                As a sub-distributor, you handle a network of smaller customers and end-users on a medium scale. If you have two to four freezers and are seeking the most profitable way to use them, you could become one of Hellinda sub-distributors.
                </p>
            </div>

            <div className="card-footer">
                <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>

    </div>

    <div className="card-row">
        <div className="card">
            <div className="card-image">
            <img src={coldroom} alt='Retailer' />
            </div>
            <div className="card-title">
                <h4>Distributors </h4>
            </div>
            <div className="card-content">
                <p>
                As a Hellinda distributor, you serve as Hub, for stocking products in large quantities for distribution into smaller channels like.
For this category, you must have a large capacity for cold storage and the ability to redistribute, operating a standard ordering process. After production Large quantity of our frozen product will be shift to your shop.

                </p>
            </div>
            <div className="card-footer">
            <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>


        <div className="card">
            <div className="card-image">
            <img src={logistics} alt='Retailer'/>
            </div>
            <div className="card-title">
                <h4>LOGISTICS </h4>
            </div>
            <div className="card-content">
                <p>
                We are seeking investors in our logistics sector. logistic firms and individuals in the logistics industry who are passionate about providing the finest animal protein delivery experience possible. With access to hellinda Foods premium products, brand recognition, and consumer insights, this is an excellent chance to create and grow a profitable logistics business system. Join our community of logistics partners to drive seamless delivery of quality along our value chain across Nigeria. You must not have logistic facilities to partner with us, invest the cash value of bike or car and get huge return on your investment at the end of a year or two at the comfort of your home. Register and be part of our profit sharing.
                </p>
            </div>

            <div className="card-footer">
                <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>

        <div className="card">
            <div className="card-image">
            <img src={logistics} alt='Retailer'/>
            </div>
            <div className="card-title">
                <h4>LOGISTICS </h4>
            </div>
            <div className="card-content">
                <p>
                Do you have a minimum of one 10 ton refrigerated truck that can facilitate the movement of finished products from the abattoirs to depots and other key distribution spots across Nigeria? If you answered yes, then register and become our logistic partner. The locations and routes of business operations are country-wide. You must not buy the vehicle. invest the cash value and get huge return on your investment at the end of a year or two at the comfort of your home. Register and be part of our profit sharing.
                </p>
            </div>

            <div className="card-footer">
                <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>

    </div>

    <div className="card-row">
        <div className="card">
            <div className="card-image">
            <img src={truck} alt='birds transport truck' />
            </div>
            <div className="card-title">
                <h4>Birds Transport Logistict </h4>
            </div>
            <div className="card-content">
                <p>
                If you are a logistics operator with capacity to transport live birds in large quantities, and under conducive conditions from the farms to the abattoirs, then join us to discover how we can move your business forward. You can also do this at the comfort of your home, you must not buy the vehicle invest the cash value and get huge return on your investment at the end of a year or two at the comfort of your home. Register and be part of our profit sharing.
                </p>
            </div>
            <div className="card-footer">
            <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>


        <div className="card">
            <div className="card-image">
            <img src={Tricycle} alt='Retailer'/>
            </div>
            <div className="card-title">
                <h4>Tricycle Logistics </h4>
            </div>
            <div className="card-content">
                <p>
                Your Logistics companies or individual operators providing last-mile delivery services should possess the facility to operate seamless, small scale (tricycles, vans, and motorbikes) or large-scale (refrigerated trucks) delivery operations. Our operations span across major cities in Nigeria. You must not buy the vehicle, invest the cash value and get huge returns on your investment at the end of a year or two at the comfort of your home. Register and be part of our profit sharing.
                </p>
            </div>

            <div className="card-footer">
                <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>

        <div className="card">
            <div className="card-image">
            <img src={dispatch} alt='Dispatch Riders'/>
            </div>
            <div className="card-title">
                <h4>Dispatch Rider Logistics </h4>
            </div>
            <div className="card-content">
                <p>
                Your Logistics companies or individual operators providing last-mile delivery services should possess the facility to operate seamless, small scale (tricycles, vans, and motorbikes) or large-scale (refrigerated trucks) delivery operations. Our operations span across major cities in Nigeria. You must not buy the vehicle, invest the cash value and get huge returns on your investment at the end of a year or two at the comfort of your home. Register and be part of our profit sharing.
                </p>
            </div>

            <div className="card-footer">
                <a href='/investors' className="card-btn">Join Us</a>
            </div>
        </div>

    </div>
    
     </div>
    </div>
  )
}

export default Investment
