import React, { useEffect , createContext, useContext, useState} from 'react'
import "./main.scss"
import "./slideshow.css"
import "./slider.css"
import Shop from '../shop/Shop'
import Slider from './Slider'


const Main = () => {
   
  return (
    <div className='main'>
       <Slider/>
    <div className="container" >
        <Shop/>
    </div>
</div>
  )
}

export default Main