import React, { useContext } from 'react'
import "./carts.css"
import { ShopContext } from '../context/ShopContext';
import { Link } from 'react-router-dom';
import { Server } from "../../components/api/server"
let server = new Server()

const Carts = () => {

  const GlobalState = useContext(ShopContext)
    let {cartitems, increaseCart, decreaseCart, setTotalCart} = GlobalState;
    let total = 0;

    const createCheckout=()=>{
        let loggedinuser = JSON.parse(localStorage.getItem("logedinuser"));
        if(!loggedinuser){
          localStorage.setItem("myurl", "carts")
          window.location = "/login"
        }else{
          localStorage.removeItem("myurl")
          window.location = "/checkout";
        }
    }

  return (
    <div className={"carts-items" }>
      <div className='cartItems'>
     
      {cartitems.map((item, index) => {
        total += item.quantity * item.price
        if(item.quantity===0){
          item.quantity=1;
        }
          return <div className="cart-card" key={index}>
             <img src={`${server.url}/${item.imageurl}`} alt="" /> 
            <h3>{item.name}</h3>
            <p>Price: {item.price}</p>
            <b>Quantity: {item.quantity}</b>
            <b>Sub Total: {item.price * item.quantity}</b>
            <div className="cartitem-header">
              <button className='minus' onClick={()=> {decreaseCart(item)}}>-</button>
              <input type='number' className='count' value={item.quantity}  min={1} onChange={()=>{}}/>
              <button className='plus' onClick={()=>{
                 increaseCart(item);
                 
                 }}> + </button>
            </div>
          </div>
        
      })}
      </div>
      <div className='cart-checkout' >
        <>
        <h4
         style={{background:"#FFF", color:"grey", padding:"6px 30px", border:"none", borderRadius:'5px', marginBottom:"10px"}}
        >Total: {total} </h4>
        <Link to={"/shopping"}
         style={{background:"#FFA500", color:"white", padding:"6px 30px", border:"none", borderRadius:'5px'}}
        >Continue Shopping</Link>
        </>
          {/* :<b>Cart is currently empty</b> */}
          <span className='checkout-btn'
          style={{background:"blue", color:"white", padding:"6px 30px", border:"none"}}
          onClick={()=>{
            setTotalCart(total);
            createCheckout()
          }}> Checkout</span>
      </div>
    </div>
  )
}

export default Carts