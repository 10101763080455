import React, { useContext, useEffect, useState } from 'react'
import api from '../../components/api/api'
import "./dashboard.css"
import { Server } from "../../components/api/server"
import PayCard from "../../images/bank-card.webp"
let server = new Server()

const Checkout = () => {
  const [user, setUser] = useState({})
  const [cartitems, setCartItems] = useState([])
  let total =0;
  

  useEffect(()=>{
    setUser(JSON.parse(localStorage.getItem("logedinuser"))||{})
    const existingCart = JSON.parse(localStorage.getItem("cartitems")) || []
    setCartItems(existingCart)
  },[])

const checkoutOrder =  () =>{
  api.post("/orders", {userid:user.id,cartitems}).then(({data}) => {
    localStorage.removeItem("cartitems");
    window.location = "payment";
  }).catch((error)=>{
    console.log(error)
  })
}
  
  return (
    <div className='checkout'>
      <h3 className='title'>Order Summary </h3>

      <div className="checkout-row">
        <div className="customer">
          <div className="customer-details">
            <h3>Name: {user.firstname} {user.surname}</h3>
            <p style={{color:"darkgreen"}}> Address: {user.address}</p>
          </div>
        </div>
        <div>
          <div className="product-details">
            
          </div>
        </div>
      </div>
      <hr />
      <section className="payment-container">
        {cartitems.map((item)=>{
          total += item.price * item.quantity;
         return (
          <div className='column'>
            <div className="row">
            <div className="item image-item"><img src={`${server.url}/${item.imageurl}`} alt="" /> </div>
               <span className='item'>{item.name}</span>
               {/* <span className='item'>{item.quantity}</span> */}
               <span className='item'>{item.quantity} x {item.price}</span>
               {/* <span className='item'>{item.price * item.quantity}</span> */}
            </div>
            <hr />
        </div>
         )
        })}
         <div className="checkout-row">
         <img src={PayCard}/>
          <span className="paynow" onClick={checkoutOrder}>
          Pay Now {}
          <span>{total}</span>
        </span>
         </div>
        
      </section>
    </div>
  )
}

export default Checkout