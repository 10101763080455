import React from 'react'
import thighs from "./../../images/m1.PNG"
import "./slider.css";

const SliderContent = ({activeIndex, imageSlider}) => {
  return (
    <section  className={"slider"}>
     
        {imageSlider.map((slide, index)=>(
              <figure   key={index}
              className={index === activeIndex ? "slider active" : "inactive"}>
               <div className='slide'>
               <div className="slide-image">
               <img src={slide.urls} alt="" />
                    <a href='/shopping' className="slide-adverts">
                        <div>
                            <img src={thighs} alt="" />
                        </div>
                        <p>
                        <div>{}</div>
                        </p>
                        <span>Buy Now</span>
                    </a>
                </div>
                <div className="slide-description">
                    <h4>{slide.title}</h4>
                    <p>{slide.description}</p>
                    <a href='/main-content'class="read-more">Read More </a>
                </div>
               </div>
          </figure>
        ))}
    </section>
  )
}

export default SliderContent