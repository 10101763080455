import React, { useEffect, useState } from 'react'
import SliderContent from './SliderContent'
import imageSlider from './imageSlider'
import Arrows from './Arrows'
import "./slider.css"
import "./slideshow.css"


const len = imageSlider.length - 1;

const Slider = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(()=>{
        const inverval = setInterval(()=>{
            setActiveIndex(activeIndex === len ? 0 : activeIndex +1)
        }, 7000);
        return ()=>clearInterval(inverval);
    }, [activeIndex])

  return (
    <div className='slider-container'>
        <SliderContent activeIndex = {activeIndex} imageSlider={imageSlider}/>
        {/* <Arrows 
            prevSlide={()=> setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)} 
            nextSlide={()=> setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)} 
        /> */}
        {/* <Dots activeIndex={activeIndex} imageSlider={imageSlider} onclick={(activeIndex)=>setActiveIndex(activeIndex)}/> */}
    </div>
  )
}

export default Slider