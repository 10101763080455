import React from 'react'
import './products.css'
import crop from "../../images/soyabean-banner-4.jpg";
import rice from "../../images/rice.jpg";
import maize from "../../images/maize.jpg";
import chicken from "../../images/manychicks_bgwhite.jpg";
import soyabean from "../../images/soybeans.jpg";
import ventilator from "../../images/Tunnel-Ventilation.jpg";
import harvester from "../../images/conbine-hervester.png";
import chicken2 from "../../images/multiple_chicks.jpg";
import Shop from '../../components/shop/Shop';


const Crops = () => {
  return (
    <div className='crop-container'>
        <div className="crop-background">
            <img src={crop} alt="" />
        </div>
    <div className='content-section'>
    <div class="headers">
        <div class="titles">
        <h1 class="title-text">Farmers For Our Brands.</h1>
        </div>
       <div class="row-lines">
            <span class="first-line"></span>
            <span class="middle-line"></span>
            <span class="last-line"></span>
       </div>
       <p>
       The brands of Hellinda Farms work with small family farmers and ranchers across the country, who carry on an agricultural tradition with the intention to hand over to their children and grandchildren. Networks are comprised of cattle ranchers, sheep farmers, hog farmers, poultry farmers and crop farmers, who must follow a specific (and strict!) set of farming protocols to raise livestock and crop for Hellinda brands
       </p>
       <p>
       Here, we bring more farmers together and share about their hard work, day-to-day farming life and challenges, plus efforts we are taking to support them and ensure that their families can farm for years to come.
        <span> </span> <a href="/signup"> <b>Register</b> to be part of us today, invest a particular amount and part of our profit sharing.</a>

       </p>
    </div>
    <div className="content-row">
        
        <div className="content-column">
            <div className="crop-image">
            <img src={chicken} alt="" />
            </div>
            <h3 className='crop-title'>
                Chicken Farm
            </h3>
            <p className="description">
            Each order supports: Our family of farmers, who preserve land through sustainable farming practice
            </p>
            <a href="/chickens" className="crop-link">Read More</a>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={soyabean} alt="" />
            </div>
            <h3 className='crop-title'>
                Soyabeans Farm
            </h3>
            <p className="description">
            Each order supports: Our family of farmers, who preserve land through sustainable farming practice
            </p>
            <a href="/crops" className="crop-link">Read More</a>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={rice} alt=""/>
            </div>
            <h3 className='crop-title'>
            Rice Farm
            </h3>
            <p className="description">
                Description
            </p>
            <a href="/crops" className="crop-link">Read More</a>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={maize} alt=""/>
            </div>
            <h3 className='crop-title'>
                Maize Farm
            </h3>
            <p className="description">
                Description
            </p>
            <a href="/crops" className="crop-link">Read More</a>
        </div>

    </div>
    </div>
    <div className="content-continue">
        <h3 style={{display:"flex", width:'100%', justifyContent:"center", background:'#F7F7F9', padding:'10px'}}>
            Our Farmers and Ranchers and Their Families
        </h3>
        <div className="content-row">
        <div className="content-column">
            <img src={ventilator} alt="" />
        </div>
            <div className="content-column">
                <p>
                The farmers who invest in hellinda farms to grow crops and rear their animals are part of a completely integrated system. We grow our own grain to make our own nutritious feed to nourish the chickens we raise. The quality of grain that goes into our feed helps chickens thrive and ultimately (and we believe) improves the flavor of our food products. Regenerative practices are the key to our success and ensure that we can continue farming for generations to come.
                </p>
                <ul>
                    <li>
                    	<b>Broadening Fertilizer Options</b>: Planting cover crops that scavenge nutrients and biologicals that promote synergistic benefits with plant roots to create nitrogen are supporting efforts to reduce the number of synthetic fertilizers used to produce row crops.
                    </li>
                    <li>
                    	<b>Irrigation Management</b>: Green farming practices slow water runoff and leaching of nitrous oxide (N2O) into the soil due to heavy rainfall or too much irrigation.
                    </li>
                </ul>
            </div>
        </div>
        <div className="content-row">
       
            <div className="content-column">
               
                <ul>
                    <li>
                    	<b>No-Till or Strip-Till Farming</b>: Extensive plowing of farmland releases carbon dioxide into the atmosphere. Using a technique that slices through the soil surface and drops seeds in without excessive turnover keeps CO2 in the soil and prevents soil runoff into precious waterways. As plants grow, they capture additional CO2 from the environment and absorb nutrients like nitrogen that may leach into the soil, a win-win!
                    </li>
                    <li>
                    	<b>Broadening Fertilizer Options</b>: Planting cover crops that scavenge nutrients and biologicals that promote synergistic benefits with plant roots to create nitrogen are supporting efforts to reduce the number of synthetic fertilizers used to produce row crops.
                    </li>
                    <li>
                    	<b>Compost and Manure Management</b>: Organic matter is essential for healthy soil, as it captures nutrients, retains moisture, and provides a good food source for microbes that in turn stimulate plant growth. Placing organic matter and nutrients back into the soil is a circular way to recycle nutrients from poultry production at hellinda farms.
                    </li>
                   
                </ul>
            </div>
        <div className="content-column">
            <img src={harvester} alt="" />
        </div>
        </div>
        <p>
        	<b>Targeted Use of Herbicides</b>: To bolster the health of pollinators and At Hellinda Farms, sustainable agriculture practices are vital to our mutual business success. Hardworking and investor farmers know that to pass farmland down to the next generation of farmers in their families, they must do all they can to keep the soil healthy, prevent runoff into our precious streams and waterways and limit the amount of carbon dioxide (C02) that is emitted into the environment. Here, we review regenerative farming techniques that will protect our planet for years to come.Sustainable agriculture is farming in such a way as to protect the environment, aid and expand natural resources and make the best use of non-renewable resources (i.e. fossil fuels, etc.).
            <p>
            	We decided to try out the Pilot Program because we felt like it would be an opportunity to document the regenerative practices we are implementing on the farm now, and then build off of them going forward. We hope that the future sees us expanding our current work onto more acres, adopting the next generation of practices, and leveraging all of the above for better yields and economic incentives
            </p>
        </p>
        <h3 style={{display:"flex", width:'100%', justifyContent:"center", background:'#F7F7F9', padding:'10px'}}>
        Green Farming Practices: Hellinda Poultry section
        </h3>
        <p>
        Hellinda farms poultry section is currently on a billion naira poultry tunnel system and is calling on investors to come invest to be part  our huge return on investment of 60% in 2 years
        </p>
        <div className="content-row">
        <div className="content-column">
            <img src={chicken2} alt="" />
        </div>
        <div className="content-column">
            <ul>
                <li>
                    <p>
                    	<b>Organic Fertilizer</b>: Believe it or not, chicken manure (also called chicken litter) makes an excellent fertilizer. Hellinda Grain farming uses chicken litter in lieu of synthetic fertilizers. In the world of green farming, it’s a pretty big deal!
                    </p>
                </li>
                <li>
                    <p>
                    	<b>Nutrient Management</b>: That being said, too much of a good thing may do more harm than good. Many states require farmers to maintain nutrient-management plans. Plans are in place to track and monitor how much fertilizer and organic matter are applied to crops based on soil tests. Proper management prevents nitrogen and phosphorus, a byproduct of organic fertilizer, from finding its way into nearby waterways, thereby protecting wildlife and ecosystems.
                    </p>
                </li>
                <li>
                    <p>
                    	<b>Litter Sheds</b>: These covered structures help with management of poultry manure. The size of the shed is determined by the size of the farm. Proper storage helps keep farms clean and tidy – and keeps poultry manure (organic fertilizer) on the farm and out of the water.
                    </p>
                </li>
            </ul>
        </div>
        </div>
        <p>
                    	<b>In-Vessel Composting Technology</b>: In a true circle of life, soil, litter and even poultry are key ingredients in organic matter that is used to nourish crops. Those inputs are, in turn, used to replace synthetics fertilizers in crop production.
                    </p>
                    <p>
                    	<b>Concrete or Heavy Use Pads</b>: Another tool in farmers’ to prevent fertilizer from reaching waterways, pads can be placed at each end of a chicken house to capture litter or debris that is tracked outside by various farming practices. Once debris is trapped, it can be swept back into the house
                    </p>
                    <p>
                    	<b>Storm Water Management</b>: It’s all about keeping nutrients where they belong – on the farm. Hellinda farms incorporate ponds and rock beds on their farms that help capture and absorb storm water and prevent runoff.
                    </p>
                    <p>
                    	<b>Solar Panels</b>: To keep electric and fuel costs low, hellinda poultry section is on a project to place solar panels on their farms. When placed in the grassy areas between chicken houses, the panels can double as shade structures, a requirement for organic and free-range poultry certification
                    </p>
                    <p>
                    We recognize that producing more with less is not the full measure of sustainability, and that we need to take a holistic approach that overlaps with our commitments to food safety and quality, animal welfare, associate well-being, consumer preferences, community concerns and supporting family farms. To be sustainable, practices must be repeatable and integrated into our daily operations.
                    </p>
    </div>
    <Shop/>
    </div>
  )
}

export default Crops
