import React, { useContext, useState } from 'react'
import "./signup.scss"
import admin from "../../images/administrator-male.png"
import { useNavigate } from 'react-router-dom';
import api from '../../components/api/api';
import { ShopContext } from '../../components/context/ShopContext';

const Careers = () => {
  const {loader, resetLoader} = useContext(ShopContext);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
      email: '',
      surname: '',
      firstname: '',
      othernames: '',
      state: '',
      phone: '',
      password: '',
      usertype: 'Career',
    });
    const onChangeValue = (e) => {
      setUserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 

    const saveData = (e) => {
      e.preventDefault();
      resetLoader(true);
      api.post("/users", userInfo).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("logedinuser", JSON.stringify(data.user))
          navigate("/login");
        }
        resetLoader(false);
      }).catch(error => {
        resetLoader(false);
        if(error.response.status==409){
          alert("Email already regisered, login please!")
        }
      })
    }
  return (
    <div className='form-container'>
      <div className="center-content">
        <div className='admin-male'>
          <span onClick={()=>navigate("/login")}> Login</span>
          <img src={admin} alt="" />
          <label onClick={()=>navigate("/recoverpassword")} className='forgotpassword'>Forgot Password</label>
        </div>
      <div>
      <form>
        <h3 style={{color:"green"}}>CREATE YOUR FREE CAREER ACCOUNT WITH HELLINDA FARMS & ALLIED (GROW WITH US)</h3>
        <div className='form-group'>
          <input type="text" name="email" id="" className='form-control' placeholder='Email address'
          onChange={onChangeValue}
          />
        </div>
        <div className='form-group'>
          <input type="text" name="phone" id="" className='form-control'
            onChange={onChangeValue}
          />
        </div>
       
        <div className='form-group'>
          <input type="text" name="surname" id="" className='form-control'
            onChange={onChangeValue} placeholder='Phone number'
          />
        </div>
        <div className='form-group'>
          <input type="text" name="firstname" id="" className='form-control'
            onChange={onChangeValue} placeholder='First name'
          />
        </div>
        <div className='form-group'>
          <input type="text" name="othernames" id="" className='form-control'
            onChange={onChangeValue} placeholder='Othername'
          />
        </div>
        <div className='form-group'>
          <input type="text" name="state" id="" className='form-control'
            onChange={onChangeValue} placeholder='State of residence'
          />
        </div>
        <div className='form-group'>
          <input type="text" name="town" id="" className='form-control'
            onChange={onChangeValue} placeholder='Town/City code'
          />
        </div>
        <div className='form-group'>
          <input type="text" name="address" id="" className='form-control'
            onChange={onChangeValue} placeholder='Residential address'
          />
        </div>
        <div className='' style={{width:'94%', marginLeft:'3px'}}>
          <div className="password-wrapper">
            <input 
            type={
              showPassword ? "text" : "password"
            }
            onChange={onChangeValue} placeholder='Create password'
            name="password" 
            id="" className='form-control'/>
            <span>
              {showPassword ? <i class="fa fa-eye" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i> 
              :  <i class="fa fa-eye-slash" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i>
              }
            </span>
          </div>
        </div>
        <div className='form-group'>
          <button type="submit" onClick={saveData} className='button-control' style={{background:'#0085BC',opacity:'.8'}}>
         {loader ?  <span><span className="loader"></span> <span>Processing please wait</span></span>
         :" Create Account"
         
        }
          </button>
        </div>
      </form>
      </div>
      </div>
    </div>
  )
}

export default Careers